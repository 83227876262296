import React, { useContext } from "react"
import Layout from "../../../components/layout"

import ThemeContext from "../../../context/ThemeContext"
import { graphql } from "gatsby"
import GenericLandingContentWrapper from "../../../components/text-helpers/generic-landing-content-wrapper"
import SEO from "../../../components/seo"
import GameProvidersCards from "../../../components/game-providers-cards/game-providers-cards"
import localize from "../../../components/localize"

const Content = ({ licences, translations, locale }) => {

  return (
    <GameProvidersCards iterable={licences} iterableName={"licences"} translationName={"licence"} translations={translations} locale={locale} />
  )
}

const Licences = ({ data, pageContext, location }) => {
  const { locale, alternateLocaleSlugs } = pageContext
  let { edges } = data.allSanityLicenceType
  let nodes = edges.map(x => x.node)

  let { translations } = useContext(ThemeContext)

  const pageData = data.sanityGenericLandingPage

  return (
    <Layout  breadcrumbNameOverride={pageData.breadcrumbName} silo={data.sanitySilo} pageContext={pageContext} location={location}>
      <SEO location={location} alternateSlug={alternateLocaleSlugs}
        title={pageData && pageData.seoTitle}
        description={pageData && pageData.seoMeta}
      />
      <GenericLandingContentWrapper pageData={pageData && pageData}>
        <Content translations={translations} locale={locale} licences={nodes} />
      </GenericLandingContentWrapper>
    </Layout>
  )
}

export default localize(Licences)
export const query = graphql`
  query LicencesQuery{
    sanitySilo (_id: {eq: "7caede39-eebc-4663-b82c-90ac5520d920"}){
        _id
        breadcrumbTrail {
            ...LocaleSiloTrails
        }
    }
    allSanityLicenceType {
      edges {
        node {
          name
          icon {
            asset {
                fluid {
                    ...GatsbySanityImageFluid
                }
            }
          }
        }
      }
    }
    sanityGenericLandingPage(
      name: { eq: "Licenses Landing" }
    ) {
      ...GenericLanding
    }
  }
`
